.contenitoreBlog{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  font-family: Satoshi, sans-serif;
  color: #25182c;

}
.category-label {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: rgba(101, 50, 121, 0.1);
  color: #653279;
  font-weight: 700;
  text-transform: capitalize;
}

.titoloPagina{
  margin-top: 16px;
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 120%;
    font-weight: 700;
}

.contenitoreBlog .p.description.big {
  margin-bottom: 0px;
  font-size: 18px;
  color: #55575b;
  line-height: 150%;
}
.divider {
  width: 100%;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #ddd;
}

.blog-thumb, .blog-thumb-detail {
  width: 100%;
  height: 520px;
  border-radius: 20px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
.blog-card-image{
  width: 100%;
  height: 327px;
  min-height: 327px;
  border-radius: 20px;
  
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.author-img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  border-radius: 64px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.author-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-card-wrapper {
  width: 48%;
  margin-bottom: 48px;
}
.white{
  color: #fff;
}
.blog-card-content {
  padding-top: 32px;
}

.h4Title {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 120%;
    font-weight: bold;
}

.p {
  font-size: 16px;
  line-height: 150%;
  
}

.p.description {
  margin-bottom: 24px;
  color: #55575b;
}

.artRecent{
  font-size: 32px;
  line-height: 150%;
  font-weight: bold;
  color: #000;
}
.section {
  padding-top: 75px;
    padding-bottom: 75px;
}

.categories-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-bottom: 1px solid #ddd;
}
.category-link.w--current {
  border-bottom: 2px solid #653279;
  color: #25182c;
}
.category-link {
  margin-right: 40px;
  padding-top: 12px;
  padding-right: 0px;
  padding-bottom: 12px;
  color: rgba(37, 24, 44, 0.5);
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
a {
  background-color: transparent;
}

.hero_section.blog-category {
  height: 40vh;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))), url('../images/ecoturismo.jpeg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/ecoturismo.jpeg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.content_wrapper.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content_wrapper.flex.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content_wrapper.flex.vertical {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.titleCat{
  margin-top: 16px;
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 120%;
    font-weight: bold;
}

.data {
  font-size: 18px;
  font-weight: 500;
}

.container-articolo {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}
.content_wrapper.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content_wrapper.flex.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content_wrapper.flex.vertical {
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.content_wrapper.flex.align-top {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.content_wrapper.flex.align-top.margintop32 {
  margin-top: 48px;
}

.content_wrapper.flex.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.blog-card-wrapper {
  width: 48%;
  margin-bottom: 48px;
}

.selectedCategory{
color: #25182C !important;
font-weight: 700;
font-size: 16px;
line-height: 24px;
border-bottom: 1px solid #653279;
}

.container-articolo.stroke {
  padding-top: 150px;
  padding-bottom: 150px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.hero_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.content_wrapper.flex.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.blog-text-wrapper.hero {
  width: 45%;
  margin-bottom: 72px;
}

.bodycontent {
  width: 65%;
}
.bodywrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
}

.blog-thumb.hero {
  width: 90%;
  height: 480px;
}

.contenitoreBlog .linkArt{
  text-decoration: none;
  color: #25182c !important;
}

.content-box-blog{
  width: 45%;
}
.alignCenter{
  align-items: center;
}

@media screen and (max-width: 991px) {
.contenitoreBlog{
  padding: 10px;
}
}

@media screen and (max-width: 767px) {
  .contenitoreBlog{
    padding: 10px;
  }
  }

@media screen and (max-width: 479px) {
.contenitoreBlog .flex-content-between{
  flex-direction: column-reverse;
}
.contenitoreBlog{
  padding: 10px;
}

.content-box-blog{
  width: 100%;
}
.content-box-blog .titoloPagina{
  font-size: 32px
}

.blog-thumb .blog-thumb-detail{    
height: 230px;
}

.category-label {
    font-size: 12px;
    margin-top:8px;
}
.contenitoreBlog .p.description.big{
  font-size: 16px;
}
.container-articolo .titleCat{
  font-size: 32px ;
}
}

