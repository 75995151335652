.modalRegistrati .modal-dialog{
  width: 1192px;
  height: 799px;
}
.modal{
 width:100% !important;
 --bs-modal-width:100% !important;
 min-height: 799px !important;
}

.modalRegistrati .modal-body{
padding: 56px;
}

.bloccoForm{
  width: 50%;
  height: 100%;
}

.marginAuto{
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.marginTop160{
  margin-top: 160px !important;
  
}
.AccRegi{
  padding: 3px 0px 10px 5px !important;
}
.flex-column-center{
  display: flex;
    flex-direction: column;
    justify-content: center;
}

.imgRegistrati{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.modal-dialog{
  overflow-y: initial !important
}
.modal-body{
  overflow-y: auto;
}
.inputModalDiv{
  width:327.65px;
  margin:auto;
}

.labelModalRegistrazione{
font-weight: 700;
font-size: 14px;
line-height: 16px;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #2D3648;
}
.input-modal{
  margin-top:0px;
}
.marginBottom0{
  margin-bottom: 0px !important;
}

.title-modal{
font-weight: 700;
font-size: 32px;
line-height: 140%;
color: #25182C;
text-align: center;
}

.marginBottom20{
  margin-bottom:20px ;
}

.labelModalRegistrazioneGrey{
font-weight: 400;
font-size: 12px;
line-height: 24px;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
}
.privacyLogin{
  font-weight: 400;
font-size: 16px;
line-height: 22px;
letter-spacing: -0.01em;
color: #25182C;
}
.linkTermini{
  font-weight: 700;
  text-decoration: underline;
}
.linkTermini a,.linkTermini a:hover{
  color: #25182C;
}

.privacy{
font-weight: 400;
font-size: 12px;
line-height: 22px;
letter-spacing: -0.01em;
color: #25182C;
}
.alighRight{
text-align: right;
display: block !important;
}

.pwd-container input {
  padding: 5px 10px 5px 10px;
}
.pwd-container {
  position: relative;
}
.pwd-container img {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 20px;
  top: 41px;

}
.img-content{
  width: 50%;
}

.flex-content-around {
  display: flex;
  flex-direction: row;
}

.paddingBottom10{
  padding-bottom: 10px;
}
.btn-login{
  height: 56px ;
  padding-top: 16px ;
  padding-bottom: 16px;
  font-size: 16px;
    align-items: center;
    gap: 8px;
    width: 100%;
    background: #653279;
    border-radius: 20px !important;
    color: #FFFFFF;
}

.pwdDimenticata{
font-weight: 700;
font-size: 12px;
line-height: 24px;
color: #653279 !important;
text-decoration: none !important;
padding-left: 0px !important;
}

button.pwdDimenticata:focus{
  border: none;
}
.AccRegi{
  color: #653279 !important;
}
.help-block-login{
 height: 56px;
 border-radius: 16px;
 border: 1px solid #FF7878;
 color:#25182C;
 margin-bottom: 16px;
 padding: 14px;
}
.textError{
  padding: 15px;
}

@media screen and (max-width: 479px) {
  .bloccoForm{    
  width: 100%;
}

.flex-content-around.accedi{    
display: flex;
flex-direction: column-reverse;
}

.modalRegistrati .modal-dialog{
  width: 100% !important;
  position: absolute;
  padding-left: 0px !important;
  margin-left: 0px;
}
.modalRegistrati .modal-body {
  padding: 20px;
}
.img-content{
  display: none;
}
.title-modal{
  text-align: left;
}
.modal{
min-height: 110vh !important;
bottom: 0;
left: 0;
top:0;
position:absolute;
}
.modalRegistrati .modal-content{
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-body{
min-height: 100vh;
}
.titoloModalAccedi{
  margin-left: 0px !important;
 
}
.title-modal{
  font-size: 20px;
  margin-bottom: 32px;
}

}

@media screen and (max-width: 767px) {
  .bloccoForm{    
    width: 100%;
  }
  
  .flex-content-around.accedi{    
  display: flex;
  flex-direction: column-reverse;
  }
  .modalRegistrati .modal-dialog{
    width: 100% !important;
    position: absolute;
    padding-left: 0px !important;
    margin-left: 0px;
  }
  .img-content{
    display: none;
  }
  .title-modal{
    text-align: left;
  }
  .modal{
  min-height: 110vh !important;
  }
  .modalRegistrati .modal-content{
    border-radius: 20px;
    border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  }
  .modal-body{
    min-height: 100vh;
    }    
  
  
  }


@media screen and (max-width: 991px) {
  .bloccoForm{    
    width: 100%;
  }
  
  .flex-content-around.accedi{    
  display: flex;
  flex-direction: column-reverse;
  }
  .modalRegistrati .modal-dialog{
    width: 100% !important;
    position: absolute;
    padding-left: 0px !important;
    margin-left: 0px;
  }
  .img-content{
    display: none;
  }
  .title-modal{
    text-align: left;
  }

  .modal{
    min-height: 110vh !important;
    }
  
  .modalRegistrati .modal-content{
    border-radius: 20px;
    border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  }    
  .modal-body{
    min-height: 100vh;
    }

    .flex-content-around{
     display: inline;
    }
  
    .titoloModalAccedi{
      width: 327.65px;
      margin: auto;
      margin-bottom: 10px;
    }
  }