.w-input, .w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #25182C;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.w-tab-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 9px 30px;
  text-align: left;
  cursor: pointer;
  color: #222222;
  background-color: #dddddd;
}
.w-tabs {
    position: relative;
}
.w-tab-menu {
    position: relative;
}

.w-tab-content {
  display: block;
  overflow: hidden;
}

.container {
  width: 100% !important;
  height: 100% !important;
  max-width: 1200px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding-right: 24px !important;
  padding-left: 24px important;
}

.shape-orange {
    position: absolute;
    left: -19%;
    top: -13%;
    right: auto;
    bottom: auto;
    z-index: -1;
    overflow: hidden;
  }
  
  .shape-orange.purple {
    left: 18%;
    top: -15%;
  }
  
  .quote {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 150%;
    font-weight: 700;
    text-align: center;
  }
  
  .quote em {
    color: #ffaa4d;
  }
  
  .author {
    font-size: 18px;
    font-weight: 400;
  }
  
  .author.bold {
    font-weight: 900;
  }
  
  .author.bold.white {
    color: #fff;
  }
  
  .page_wrapper {
    overflow: hidden;
  }
  
  .quote_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 840px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .itinerary-wrapper {
    margin-top: 157px;
    margin-bottom: 143px;
  }
  
  .itinerary-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .left-side {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .black-point {
    width: 12px;
    height: 12px;
    border-radius: 20px;
    background-color: #000;
  }
  
  .linea-tratteggiata {
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #000;
    outline-color: #25182c;
    outline-offset: 0px;
    outline-style: dashed;
    outline-width: 1px;
  }
  
  .icon-bed {
    margin-right: 8px;
  }
  
  .pernottamento-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 32px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-itinerary {
    margin-top: -8px;
  }
  
  .hero_section.contatti {
    height: 60vh;
  /*  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/contatti-hero-image.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/contatti-hero-image.jpg');*/
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    -webkit-filter: brightness(151%) contrast(114%) hue-rotate(21deg);
    filter: brightness(151%) contrast(114%) hue-rotate(21deg);
  }
  
  .hero_section.blog-category {
    height: 40vh;
  /*  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2))), url('../images/ecoturismo.jpeg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../images/ecoturismo.jpeg');*/
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
  }
  
  .h2 {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 48px;
    line-height: 120%;
  }
  
  .h2.white {
    color: #fff;
  }
  
  .h2.nomargintop {
    margin-top: 0px;
    font-weight: 700;
  }
  
  ._2-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  ._2-col.align-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .form-block {
    margin-bottom: 0px;
    padding: 40px;
  }
  
  .field-label._100 {
    width: 100%;
  }
  
  .input-field {
    min-height: 48px;
    border: 1px solid #ddd;
    border-radius: 16px;
  }
  
  .input-field.area {
    min-height: 200px;
    margin-bottom: 24px;
    padding: 16px;
  }
  
  .input-field-wrapper {
    width: 100%;
  }
  
  .input-field-wrapper.half {
    width: 48%;
  }
  
  .input-field-wrapper.wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .button-primary {
    padding: 24px 32px;
    border-radius: 20px;
    background-color: #653279;
    font-size: 16px;
    font-weight: 500;
  }
  
  .contact-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .contact-wrapper.margin {
    margin-top: 32px;
    margin-bottom: 24px;
  }
  
  .icon-wrapper.margin {
    margin-right: 16px;
  }
  
  .text-link {
    color: #653279;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
  }
  
  .contattaci-wrapper {
    margin-bottom: 40px;
  }
  
  .social-icons-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .social-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 48px;
    height: 48px;
    margin-right: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #ddd;
    border-radius: 50px;
    box-shadow: 0 1px 32px 0 rgba(0, 0, 0, 0.05);
  }
  
  .form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .blog-text-wrapper.hero {
    width: 45%;
    margin-bottom: 72px;
  }
  
  .divider {
    width: 100%;
    height: 1px;
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: #ddd;
  }
  
  .author-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  
  
  .author-img.big {
    width: 48px;
    height: 48px;
  }
  
  .data {
    font-size: 18px;
    font-weight: 600;
  }
  
  .data.hero {
    margin-left: 16px;
    font-size: 16px;
  }
  
  .blog-thumb {
    width: 100%;
    height: 520px;
    border-radius: 20px;
  /*  background-image: url('../images/thumb-img.jpg');*/
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .blog-thumb.hero {
    width: 90%;
    height: 480px;
  }
  
  .category-label {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: rgba(101, 50, 121, 0.1);
    color: #653279;
    font-weight: 700;
    text-transform: capitalize;
  }
  
  .categories-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    border-bottom: 1px solid #ddd;
  }
  
  .category-link {
    margin-right: 40px;
    padding-top: 12px;
    padding-right: 0px;
    padding-bottom: 12px;
    color: rgba(37, 24, 44, 0.5);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
  }
  
  .category-link.w--current {
    border-bottom: 2px solid #653279;
    color: #25182c;
  }
  
  .blog-card-wrapper {
    width: 48%;
    margin-bottom: 48px;
  }
  
  .blog-card-image {
    min-height: 327px;
    border-radius: 20px;
   /* background-image: url('../images/card-image1.jpg');*/
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
  }
  
  .blog-card-content {
    padding-top: 32px;
  }
  
  .h4 {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 28px;
    line-height: 120%;
  }
  
  .load-more-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 72px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .hero_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .bodywrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 60px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px;
  }
  
  .bodycontent {
    width: 65%;
  }
  
  .blogpostimage {
    display: block;
    width: 100%;
    height: 400px;
    margin-top: 64px;
    margin-bottom: 64px;
    border-radius: 20px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .author-banner {
    margin-top: 64px;
    padding: 20px 32px;
    border-radius: 20px;
    background-color: #ffaa4d;
    background-image: url('../images/shape-transparent.svg');
    background-position: 0% 50%;
    background-size: auto;
    background-repeat: no-repeat;
    -o-object-fit: cover;
    object-fit: cover;
  }
  
  .button-secondary {
    padding: 24px;
    border: 1px solid #ddd;
    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0 15px 32px 0 rgba(0, 0, 0, 0.05);
    color: #25182c;
    font-size: 16px;
    font-weight: 700;
  }
  
  .button-secondary.mobile-visible {
    display: none;
  }
  
  .side-box {
    width: 30%;
    padding: 24px;
    border: 1px solid #ddd;
    border-radius: 24px;
    box-shadow: 0 15px 32px 0 rgba(0, 0, 0, 0.05);
  }
  
  .profilepic {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
  }
  
  .text24 {
    font-size: 24px;
  }
  
  .text24.bold {
    color: #25182c;
    font-weight: 700;
  }
  
  .tabs-wrapper {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 24px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    width: 100%;
  }
  
  .tabs-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .tabs-content {
    width: 65%;
  }
  
  .tab-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    padding: 12px 24px;
    border: 1px solid #ddd;
    border-radius: 16px;
    background-color: transparent;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    color: #222222;
    text-decoration: none;
  }
  
  .tab-link.w--current {
    border-width: 2px;
    border-color: #653279;
    background-color: rgba(101, 50, 121, 0.1);
  }
  
  .tab-link.last {
    margin-bottom: 0px;
  }
  
  .button-tertiary {
    padding: 0px 0px 8px;
    background-color: transparent;
    color: #653279;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
  }
  
  .inputfield-plain {
    height: 40px;
    margin-bottom: 24px;
    padding-bottom: 8px;
    padding-left: 16px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #000 #000 #ddd;
    background-color: transparent;
  }

  .modificaInput{

    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 16px;
    
  } 
  
  .inputfield-plain.grow {
    margin-right: 24px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  
  .form-area-personale {
    width: 100%;
    margin-top: 64px;
  }
  
  .form-area-personale.margin48 {
    margin-top: 48px;
  }
  
  .tablinksecondary {
    margin-right: 40px;
    padding-right: 0px;
    padding-bottom: 12px;
    padding-left: 0px;
    background-color: transparent;
    color: rgba(37, 24, 44, 0.5);
    font-size: 16px;
    font-weight: 700;
  }
  
  .tablinksecondary.w--current {
    border-bottom: 2px solid #653279;
    background-color: transparent;
    color: #25182c;
  }
  
 
  .viaggio-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #ddd;
    color: #25182c;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
  }
  
  .viaggio-wrap:hover {
    border-bottom-width: 2px;
    border-bottom-color: #653279;
  }
  
  .viaggio-wrap.cancellazione-cancellati {
    opacity: 0.4;
  }
  
  .viaggio-wrap.cancellazione-cancellati:hover {
    border-bottom: 1px solid #ddd;
  }
  
  .viaggio-image {
    width: 25%;
    margin-right: 24px;
    border-radius: 16px;
 /*   background-image: url('../images/viaggio-img-1.jpg');*/
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .viaggio-details {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .viaggio-wrap:hover{
    color: #25182c;
  }

  .imgDettaglioViaggio{
    border-radius: 20px;
  }
  
  .h5 {
    font-size: 24px !important;
    font-weight: 900 !important;
    line-height: 140%;
  }
  .accordion-button{
    padding-left: 0px !important;
  }
  .h5.margin24 {
    margin-top: 0px;
    margin-bottom: 24px;
  }
  
  .viaggio-txt {
    margin-bottom: 8px;
  }

  .titoloModalCancellazione{
    font-weight: 700;
    font-size: 18px;
    color: #25182C;
  }
  .subtitleModalCancellazione{
    font-weight: 400;
    font-size: 16px;
    color: #25182C;
  }
  .modalCancellazione{
    width: 573px !important;
height: 48px;
border-radius: 20px;
top:20% !important;
left:40% !important;
  }

  .modalCancellazione .btn{
    padding: 16px 24px !important;
    height: auto;
   }

  .marginRight16{
    margin-right: 16px;
  }

  .modalCancellazione .corpoModalCancellazione{
    width: 402px;
    margin-left: 48px;
  }


  .modalCancellazione  .buttonSalvaModal{
    width: auto;
  }

  .contentRiepilogo li{
    text-align: left !important;
  }
.accessibilitaVuota{
  margin-top: 40px;
}

.flex-row-bottom{
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;

}

  .alertMsg{
    margin-left: 8px;
  }

.buttonWizardAccessibilita{
  width: auto !important;
  padding-left:24px ;
  padding-right: 24px;

}
.basic-multi-select{
  width: 100%;
}

.input-read-only{
  padding-left: 0px;
}

.bottomSolid{    
border-bottom: 1px solid #FF7878;
width: 63%;
}

  .buttonAnnullaModal{
    height: 43;
    background: #FFFFFF !important;
border: 1px solid #EEEEEE !important;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
color: #25182C !important;
border-radius: 20px !important;
  }

  .status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #a0abc0;
    color: #fff;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  
  .tab-icon {
    margin-right: 8px;
  }
  
  .checkbox-field {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 24px;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
  .checkbox-label {
    width: 80%;
    margin-top: 8px;
    margin-bottom: 0px;
    font-weight: 700;
  }

  .marginBottom8{
    margin-bottom: 8px;
  }
.gap{
  gap: 40px;
}

  .checkbox {
    width: 16px;
    height: 16px;
    margin-top: 0px;
    margin-left: 0px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border: 2px solid #CBD2E0;
    margin-right:12px;
  }
  
  .elimina-account-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-bottom: 24px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    color: #25182c;
    font-weight: 500;
    text-decoration: none;
  }
  
  .risposta {
    width: 80%;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 25px;
  }
  
  .accordion_wrapper {
    margin-top: 28px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .accordion_item {
    margin-bottom: 24px;
    padding: 0px 0px 20px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #e5e3e3 #e5e3e3 #e5e5e5;
    border-radius: 0px;
    cursor: pointer;
  }
  
  .accordion_item:hover {
    border-width: 2px;
    border-color: #3d1827 #3d1827 #653279;
  }
  
  .accordion_item:focus {
    border-width: 2px 2px 3px;
    border-color: #3d1827 #3d1827 #ff8110;
  }
  
  .accordion_item_content {
    display: block;
    overflow: hidden;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    cursor: default;
  }

  .accordion-button:not(.collapsed)::after{
    background-image: var(--bs-accordion-btn-icon) !important;
  }
  
  .down_arrow {
    display: block;
    max-width: none;
    margin-left: 0px;
  }
  
  .accordion_item_trigger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    cursor: pointer;
  }
  
  .argomento_domande {
    margin-top: 64px;
    margin-bottom: 0px;
  }
  
  .icon_container {
    display: inline-block;
    margin-right: 16px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .icon_container.faq {
    display: block;
    margin-top: 8px;
    margin-right: 0px;
    margin-left: 20px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: transparent;
  }

  .modalAccessibilita{
    width: 643px !important;
    height: 332px !important;
    left: 40% !important;
    top: 20% !important;
    border-radius: 20px;
    overflow: hidden !important;
  }
  
  .domanda {
    margin-bottom: 8px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 18px;
    line-height: 150%;
    font-weight: 700;
  }

  .submissionOK{
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 8px 16px;
gap: 8px;
position: absolute;
width: 30%;
height: 40px;
background: #DEF3EA;
border-radius: 12px;
  }
  
  @media screen and (min-width: 1280px) {
    .flex-child-half.left._50 {
      margin-bottom: 0px;
    }
    .tabs-container {
      flex-direction: row;
    }
  }
  
  @media screen and (max-width: 991px) {
    .section {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .wizard,.steps{
      width: 100%;
    }

    .containerContatti ._2-col{
      flex-direction: row;
    }

    .modalChiSiamo .modal-content{
      margin-left: 0px;
      margin-right: 0px;
    }
  
    .section.nomarginbottom {
      padding-top: 64px;
    }
  
    .container.stroke {
      width: 93%;
      padding-top: 80px;
      padding-bottom: 80px;
    }
  
    .content_wrapper.flex.align-top.margintop32 {
      margin-top: 32px;
    }
  
    .content_wrapper.flex.wrap {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .content_wrapper.flex.center {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .content_wrapper.flex.center.reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .flex-child-half.right.tablet-100 {
      width: 100%;
      margin-top: 24px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .flex-child-half.right.tablet-100.reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  
    .flex-child-half._40 {
      width: 100%;
      margin-bottom: 64px;
    }
  
    .flex-child-half._40.relative.form {
      top: 0px;
      width: 90%;
    }
  
    .flex-child-half.tablet-100 {
      width: 100%;
    }
  
    .p.description.big {
      font-size: 16px;
    }
  
    .p.useremail {
      font-size: 14px;
    }
  
    .h3.nomarginbottom {
      font-size: 28px;
    }
  
    .half-column {
      width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
  
    .half-column.right {
      width: 100%;
    }
  
    .img-block {
      height: 280px;
      margin-bottom: 0px;
    }
  
    .img-block._3 {
      margin-right: 0px;
      margin-bottom: 24px;
      background-position: 50% 36%;
    }
  
    .img-block._5 {
      margin-bottom: 24px;
      background-position: 50% 16%;
    }
  
    .shape-orange {
      display: none;
    }
  
    .quote {
      margin-bottom: 8px;
      font-size: 24px;
    }
  
    .author.bold {
      font-size: 16px;
    }
  
    .hero_section.contatti {
      height: 40vh;
    }
  
    .h2 {
      margin-top: 8px;
      font-size: 38px;
    }
  
    ._2-col {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    ._2-col.align-center.tablet-horizontal {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  
    .form-block {
      padding: 56px;
    }
  
    .contact-wrapper {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .icon-wrapper.margin {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  
    .contattaci-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding-right: 15%;
      padding-left: 15%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
    }
  
    .social-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .social-link {
      margin-right: 12px;
      margin-left: 12px;
    }
  
    .blog-text-wrapper.hero {
      width: 60%;
      margin-bottom: 48px;
    }
  
    .divider {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  
    .author-img {
      margin-right: 8px;
    }
  
    .author-img.big {
      margin-right: 16px;
    }
  
    .data {
      font-size: 14px;
    }
  
    .data.hero {
      font-size: 14px;
    }
  
    .blog-thumb {
      height: 400px;
    }
  
    .blog-thumb.hero {
      width: 100%;
      height: auto;
    }
  
    .category-label {
      padding: 4px 8px;
    }
  
    .categories-wrapper {
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
  
    .blog-card-image {
      min-height: 227px;
    }
  
    .blog-card-content {
      padding-top: 16px;
    }
  
    .h4 {
      margin-top: 8px;
      margin-bottom: 16px;
      font-size: 24px;
    }
  
    .bodycontent {
      width: 85%;
    }
  
    .blogpostimage {
      height: 320px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  
    .author-banner {
      margin-top: 48px;
      padding: 16px 24px;
    }
  
    .button-secondary {
      padding-top: 16px;
      padding-bottom: 16px;
      border-radius: 20px;
    }
  
    .side-box {
      width: 30%;
      padding: 24px;
      border: 1px solid #ddd;
      border-radius: 24px;
      box-shadow: 0 15px 32px 0 rgb(0 0 0 / 5%);
    }
  
    .profilepic {
      width: 40px;
      height: 40px;
      margin-bottom: 16px;
    }
  
    .text24.bold {
      font-size: 20px;
    }
  
    .tabs-content {
      right: 0%;
      width: 66%;
    }
  
    .tab-link {
      margin-bottom: 10px;
      padding: 8px 10px;
      border-radius: 12px;
      font-size: 14px;
      line-height: 21px;
    }
  
    .tablinksecondary {
      margin-right: 18px;
      font-size: 15px;
    }
  
    .viaggi-wrapper {
      margin-top: 16px;
    }
  
    .viaggio-wrap {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  
    .viaggio-image {
      width: 40%;
      margin-right: 16px;
    }
  
    .viaggio-image._2 {
  /*    background-image: url('../images/ravello.jpg');*/
    }
  
    .h5 {
      font-size: 18px;
    }
  
    .viaggio-txt {
      font-size: 14px;
    }
  
    .status {
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 4px;
      background-color: #e4e8f0;
      color: #55575b;
      letter-spacing: 0.3px;
    }
  
    .risposta {
      font-size: 15px;
      line-height: 22px;
    }
  
    .accordion_wrapper {
      margin-top: 16px;
    }
  
    .argomento_domande {
      margin-top: 40px;
    }
  
    .icon_container {
      margin-right: 16px;
      padding: 0px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
  
    .icon_container.faq {
      margin-top: 8px;
    }
  
    .domanda {
      font-size: 16px;
    }
    .tabs-container {
      flex-direction: row;
    }
  }
  
  @media screen and (max-width: 767px) {
    .section {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .wizard,.steps{
      width: 100%;
    }
  
    .container.stroke {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  
    .p.white.big {
      font-size: 16px;
    }
  
    .label.white.medium {
      font-size: 12px;
    }
  
    .h3 {
      font-size: 24px;
    }
  
    .h3.nomarginbottom {
      font-size: 24px;
    }
  
    .img-block {
      height: 250px;
    }
  
    .img-block._2 {
      height: 250px;
    }
  
    .img-block._3 {
      height: 250px;
    }
  
    .img-block._5 {
      height: 250px;
    }
  
    .quote {
      margin-bottom: 8px;
      font-size: 20px;
    }
  
    .author.bold {
      font-size: 14px;
    }
  
    .hero_section.contatti {
      height: 90vh;
    }
  
    .h2 {
      font-size: 32px;
    }
  
    .h2.white {
      font-size: 40px;
      line-height: 56px;
    }
  
    .contact-wrapper.margin {
      margin-top: 16px;
    }
  
    .contattaci-wrapper {
      margin-bottom: 48px;
    }
  
    .blog-text-wrapper.hero {
      width: 100%;
      margin-bottom: 32px;
    }
  
    .data {
      font-size: 12px;
    }
  
    .blog-thumb {
      height: 300px;
    }
  
    .blog-thumb.hero {
      height: 250px;
    }
  
    .category-label {
      font-size: 12px;
    }
  
    .categories-wrapper {
      margin-top: 8px;
    }
  
    .category-link {
      margin-right: 24px;
    }
  
    .blog-card-image {
      min-height: 180px;
    }
  
    .h4 {
      font-size: 20px;
    }
  
    .bodywrapper {
      padding-top: 32px;
    }
  
    .blogpostimage {
      height: 230px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  
    .author-banner {
      padding: 8px 16px;
    }
  
    .button-secondary.mobile-visible {
      display: none;
    }
  
    .side-box {
      width: 100%;
    }
  
    .tabs-container {
      height: 100%;
    }
  
    .tabs-menu {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  
    .tabs-content {
      left: auto;
      top: 110%;
      right: 0%;
      bottom: auto;
      width: 100%;
    }
  
    .tab-link {
      margin-right: 8px;
      margin-bottom: 0px;
      padding-right: 8px;
      padding-left: 8px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
  
    .tab-link.last {
      margin-right: 0px;
    }
  
    .form-area-personale {
      margin-top: 32px;
    }
  
    .tablinksecondary {
      margin-right: 24px;
    }
  
    .tab-icon {
      margin-right: 0px;
      margin-bottom: 8px;
    }
  
    .accordion_item_trigger {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  
    .icon_container.faq {
      margin-top: 8px;
      line-height: 10px;
    }
    .tabs-container {
      flex-direction: column;
    }
    .side-box{
      margin-bottom: 24px;
    }
  }
  
  @media screen and (max-width: 479px) {
.contentRiepilogo .col-4 ,.contentRiepilogo .col-8{
  width: 100%;
}
.contenitoreBlog{
  margin-top: 0px;
}


    .section {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    #tab_content,.box-carousel{
      padding: 10px;
    }
  .textAreaAccess{
    width: 100%;
  }
  .wizard,.steps{
    width: 100%;
  }
  .wizard .contenuto{
    margin:0px 0px;
  }

    .content_wrapper.flex {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
  
    .content_wrapper.flex.align-top.margintop32 {
      margin-top: 32px;
    }
  
    .flex-child-half.right.mobile100 {
      width: 100%;
    }
  
    .flex-child-half._40.relative.form {
      width: 100%;
    }
  
    .flex-child-half.left._50 {
      width: 100%;
      padding-right: 0px;
    }
  
    .p.description {
      font-size: 14px;
    }
  
    .p.useremail {
      font-size: 16px;
    }
  
    .label {
      font-size: 12px;
      letter-spacing: 4px;
    }
  
    .h3.nomarginbottom {
      line-height: 130%;
    }
  
    .half-column {
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  
    .img-block {
      height: 160px;
    }
  
    .img-block._2 {
      height: 160px;
    }
  
    .img-block._3 {
      height: 160px;
      margin-bottom: 16px;
    }
  
    .img-block._5 {
      height: 160px;
      margin-bottom: 16px;
    }
  
    .hero_section.contatti {
      height: 50vh;
    }
  
    .h2.white {
      margin-bottom: 8px;
      font-size: 32px;
      line-height: 40px;
    }
  
    ._2-col.align-center.tablet-horizontal.reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  
    .form-block {
      padding: 32px 23px;
    }
  
    .input-field.area {
      min-height: 160px;
      margin-bottom: 14px;
    }
  
    .input-field-wrapper {
      margin-bottom: 15px;
    }
  
    .input-field-wrapper.half {
      width: 100% !important;
    }
    .bodyContatti .form{
      flex-direction: column;
    }
  
    .input-field-wrapper.wrap {
      margin-bottom: 24px;
    }
  
    .button-primary {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .contact-wrapper.margin {
      margin-top: 16px;
    }
  
    .contattaci-wrapper {
      margin-bottom: 48px;
      padding-right: 0%;
      padding-left: 0%;
    }
  
    .blog-text-wrapper {
      margin-top: 16px;
    }
  
    .blog-thumb {
      height: 230px;
    }
  
    .blog-thumb.hero {
      height: 230px;
    }
  
    .categories-wrapper {
      overflow: scroll;
    }
  
    .category-link {
      margin-right: 32px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  
    .blog-card-wrapper {
      width: 100%;
    }
  
    .overflow-hidden {
      overflow: hidden;
    }
  
    .bodycontent {
      width: 100%;
    }
  
    .blogpostimage {
      height: 180px;
    }
  
    .author-banner {
      margin-top: 24px;
    }
  
    .button-secondary.mobile-hidden {
      display: none;
    }
  
    .button-secondary.mobile-visible {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .profilepic {
      width: 48px;
      height: 48px;
    }
  
    .tabs-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }
  
    .tab-link {
      margin-bottom: 16px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 16px;
    }
  
    .button-tertiary {
      font-size: 14px;
    }
  
    .button-tertiary._20 {
      font-size: 14px;
    }
  
    .inputfield-plain.grow {
      margin-right: 0px;
      margin-bottom: 8px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
  
    .form-area-personale.margin48 {
      margin-top: 40px;
    }
  
    .tablinksecondary {
      padding-bottom: 8px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  
    .tabsmenusecondary {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      overflow: scroll;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  
    .viaggio-wrap {
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .viaggio-image {
      width: 100%;
      height: 180px;
      margin-right: 0px;
      margin-bottom: 16px;
    }
  
    .h5 {
      margin-top: 0px;
    }
  
    .tab-icon {
      margin-right: 16px;
      margin-bottom: 0px;
    }
  
    .risposta {
      margin-bottom: 8px;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }
  
    .accordion_wrapper {
      margin-top: 0px;
    }
  
    .accordion_item {
      margin-bottom: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  
    .argomento_domande {
      margin-top: 32px;
      margin-bottom: 0px;
    }
  
    .icon_container.faq {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  
    .domanda {
      width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
    }

    .tabs-container {
      flex-direction: column;
    }

    .react-tabs__tab-list{
      display: flex;
      overflow: scroll;
      align-items: center;
      flex-direction: row;
    }

    .contenitorePaginaDettaglio.flexColum {
      flex-direction: column;
  }
  .contentRiepilogo{
    width: 100% !important;
  }

  .side-box{
    width: 90%;
  } 

  }

  
  
  #w-node-c36e0ab7-73a3-179f-0d09-81f27d0c2b86-d77b75df {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-_908091ea-4e38-e23c-f9c5-df761055b298-d77b75df {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-_942d7ff4-20d0-dd8c-6956-864420d18548-d77b75df {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-c3ee1d6a-59b6-56fd-9433-8cafeed9f91c-d77b75df {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-_33ef19e7-7b64-eacd-c934-9d33b6bcf8d1-d77b75df {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  #w-node-_944e8f5a-2080-4d55-bbfa-f8d701fcd8f9-d77b75df {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
  
  label {
    display: block !important;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .w-input:focus,
.w-select:focus {
  border-color: #3898EC;
  outline: 0;
}

.buttonProfile{
  width: 160px;
height: 70px;
background: #653279;
border-radius: 20px;
color:#fff;
}

.react-tabs__tab--selected {
border-bottom: 2px solid #653279;
background-color: transparent;
color: #25182c !important;
}
.react-tabs{
  margin-top: 32px;
}

.cursorPointer{
  cursor: pointer;
}

.contenitorePaginaDettaglio{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.contentRiepilogo{
  width: 70%;
}

.contentMenu{
  width: 30%;
}

.flexColum{
display: flex; 
 flex-direction: row;
 align-items: flex-start;
}

.text16{
  font-weight: 500;
font-size: 16px;
line-height: 150%;
}

.contenitorePaginaDettaglio a, .contenitorePaginaDettaglio a:hover{
color: var(--bs-body-color);
text-decoration: none;
}

.btn-salva{
  background: #653279 !important;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px !important;
  color:#FFFFFF !important;
}
.btn-cancella{
  background: #FFFFFF !important;
  border: 1px solid #EEEEEE !important;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05) !important; 
  border-radius: 20px !important;
}

.accordion-item{
  border:none !important;
}

.accordion-button:not(.collapsed),.accordion-button:focus {
  color: var(--bs-accordion-btn-color) !important;
  background-color: transparent !important;
    box-shadow: none !important;
}

.no-border{
  border:none !important;
}
.marginModal{
  margin:0px 35px !important;
  
}

.ReactTags__tag{
  border-width: 2px;
  background-color: rgba(101, 50, 121, 0.1);
  border-radius: 5px;
  margin-right: 10px;
  padding:5px 5px;
  margin-bottom: 5px;
}

.ReactTags__remove{
  border: none;
    background: transparent;
}

textarea:focus, input.ReactTags__tagInputField:focus, .ReactTags__tagInputField, .textAreaAccess{
  background: #FFFFFF;
border: 1px solid #DDDDDD;
border-radius: 16px;
margin-top: 15px;
padding: 6px;
}
input.ReactTags__tagInputField{
width: 100%;
}
.ReactTags__tags{
  width: 100%;
}


