
.wizardPrenotazione {
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  margin-bottom: 100px;
  max-width: 1200px;
  height: 100%;
}
.wizard li{
  text-align: left;
}
  div.vsolid{
    border: 4px solid #DDDDDD;
    background-color: #DDDDDD;
  }

  div.done{
    border: 4px solid #FFAA4D;
    background-color: #FFAA4D;
  }

  
 
  

  .steps{
    width: 100%;
  }

  .nav li{
    text-align: center;
    margin-bottom: 13px;
  }
  .wizardLabel{
    margin-bottom: 13px;
    font-weight: 700;
font-size: 16px;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
justify-content: center;
  }
.marginBottom32{
  margin-bottom: 32px;
}
.labelWizardRiepilogo{
  margin-bottom: 16px;
}
  .wizardPrenotazione .box{
    border:none;
    align-items: center;
  }

  .labelCarouselPrenotazioni{
background: #FFFFFF;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
display: block;
padding: 10px 10px;
  }
  .carousel-prenotazione-input{
width: 100%;
    height: 45.86px;
background: #FFFFFF;
border: 1px solid #DDDDDD;
border-radius: 16px;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
padding: 10px 10px;
  }

  .marginBottom40{
    margin-bottom: 40px;
  }
  .wizard{
   width: 96%;
  }

  .wizard .titoloBoxPrenotazioni{
    font-size: 32px;
  }

  .buttonWizard{
    background: #653279;
    border-radius: 16px;
    height: 54px;
    width: 100%;
    color: #FFFFFF;
    margin: 40px 0px 0px 0px;
  }


  .buttonWizard{
    background: #653279;
    border-radius: 16px;
    height: 54px;
    color: #FFFFFF;
    margin: 40px 0px 0px 0px;
  }

  .btn-step{
    width: 47%;
  } 

  .wizard .contenuto{
    margin: 56px 0px 0px 0px;
  }
  
  .itinerary-wrapper {
    margin-top: 40px !important;
  }
  
  .itinerary-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .left-side {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .black-point {
    width: 12px;
    height: 12px;
    border-radius: 20px;
    background-color: #000;
  }
  
  .linea-tratteggiata {
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #000;
    outline-color: #25182c;
    outline-offset: 0px;
    outline-style: dashed;
    outline-width: 1px;
  }
  
  .icon-bed {
    margin-right: 8px;
  }
  
  .pernottamento-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 32px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .heading-itinerary {
    margin-top: -8px;
  }

  .modalAccessibilitaWizard{
    left: 30% !important;
  }
.modalAccessibilitaWizard .modal-dialog{
  height: auto;
}

  .linkViaggi{
    color:#653279;
    text-decoration: underline;
  }
  .marginTop60{
    margin-top: 60px !important;
  }
  .marginTop48{
    margin-top: 48px !important;
  }
  .testoPrezzo{
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: 'calt' off;
  color: #25182C;
  margin-top: 16px;
  
}

.underlineNone{
  text-decoration: none;
}

.totale{
font-weight: 400;
font-size: 24px;
line-height: 150%;
font-feature-settings: 'calt' off;
color: #25182C;
text-transform: capitalize;
}

.fontWeight700{
  font-weight: 700 !important;
}
.modalAccessibilitaWizard .modal-body{
  padding:90px 75px;
}

.contenitoreTestiAccess{
text-align: center;
}
.titleModalAccessibilita{
font-weight: 700;
font-size: 32px;
line-height: 140%;
}
.testoModalAccessibilita{
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #25182C;
}
.textAreaPrenotazione{
background: #FFFFFF;
border: 1px solid #DDDDDD;
border-radius: 16px;
margin-top: 15px;
padding: 6px;
width: 100%;
}

.marginTop56{
  margin-top: 56px;
}

.wizard100{
  width: 100%;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.wizardPrenotazione .flex-child-half{
  width: 50%;
}

.prenot{
  width:50vw;
}

.titoloWizardAccessibilita{
font-weight: 700;
font-size: 32px;
line-height: 140%;
}

.subtitleWizardAccessibilita{
  font-weight: 400;
font-size: 16px;
line-height: 150%;
display: flex;
align-items: center;
color: #55575B;
}
.domandaWizard{
  font-weight: 700;
font-size: 18px;
line-height: 150%;
letter-spacing: -0.01em;
color: #25182C;
}

.radioWizard{
  margin-right: 10px;
}
.imageWizard {
  background-size: cover;
  border-radius: 20px;
  min-height: 600px;
}
.wizard .imgItinerario{
border-radius: 20px;
object-fit: cover;
  width: 97%;
    height: 191px;
}
.undoButton{
color: #25182C;
background: #FFFFFF;
border: 2px solid #EEEEEE;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 16px;
    
}

.padding0{
  padding: 0px;
}