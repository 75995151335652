.destinationTitlepage{
font-weight: 600;
font-size: 14px;
line-height: 150%;
text-align: center;
letter-spacing: 0.2em;
font-feature-settings: 'calt' off;
color: #25182C;
text-transform: uppercase;
}
.destination{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top:120px;
  margin-bottom: 120px;
}
.destinationTitle{
  font-weight: 700;
font-size: 48px;
line-height: 120%;
text-align: center;
color: #25182C;
margin-bottom: 24px;
}

.headDestination{
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.destinationSubTitle{
font-weight: 400;
font-size: 18px;
line-height: 150%;
text-align: center;
font-feature-settings: 'calt' off;
color: #25182C;
}

.destination .card{
 
  border: none;
}
.destination .container{
  height: 120px;
}

.destinationDetail .container{
  width: 100vw !important;
  
}
.img-head{
width: 100%;
height: 600px;
}
.title-head{
  font-weight: 700;
font-size: 48px;
line-height: 120%;
letter-spacing: 0.01em;
color: #FFFFFF;
}


.trip-button{
    margin-right: 16px;
    background: #FFFFFF;
    border: none;
    border-bottom: 4px solid #FFAA4D;
    min-width: 119px;
    height: 72px;
}



.activate{
  background: rgba(101, 50, 121, 0.1) !important;
  border: 2px solid #653279 !important;
}

.roWbuttonTour{
  background: #FFFFFF;
border: 2px solid #EEEEEE;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
}

.imgButton{
  margin-right: 8px;
  margin-left: 8px;
}

.titleCorpo{
font-weight: 700;
font-size: 40px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: 0.01em;
color: #25182C;
}
.subtitleCorpo{
 font-weight: 500;
font-size: 18px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
}
.titleModalTour{
  font-weight: 700;
  font-size: 16px; 
  margin-bottom: 16px;
}
.marginTop40{
  margin-top:40px !important;
}
.marginTop16{
  margin-top:16px !important;
}

.selectCorpo{
width: 125px !important;
height: 40px;
background: #FFFFFF;
border: 2px solid #EEEEEE;
border-radius: 24px;
margin-right: 8px;
}
.buttonDisabilita{
  display: flex;
  align-items: center;
padding: 4px 16px;
height: 40px;
background: #FFFFFF;
border: 2px solid #EEEEEE;
border-radius: 24px;
margin-right: 5px;
}

.buttonDisabilita:hover,.selectCorpo:hover,.buttonPrezzo:hover, .buttonDisabilita:active{
  border: 2px solid #653279 !important;
}

.buttonDisabilita:focus, .buttonDisabilita:focus-visible{
  border: 2px solid #EEEEEE;
}

.emptyState{
  width: 100%;
  padding-top: 180px;
  padding-bottom: 180px;
  background: #F4F4F4;
border-radius: 40px;
}



.destinationDetail .card-img-top{
  height: 310px;
}

.buttonMobile{
  min-height: 40px;
  background: #FFFFFF;
  border: 2px solid #EEEEEE;
  border-radius: 24px;
  font-weight: 700;
font-size: 14px;
line-height: 24px;
margin-right: 8px;
margin-top: 8px;
padding: 8px 12px;
  }

.buttonSalvaModalTour{
  justify-content: center;
  align-items: center;
  padding: 12px 24px !important;
  gap: 8px;
  background: #653279 !important;
  border-radius: 16px !important;
  font-size: 14px !important;
}

.buttonCancellaModalTour{
  text-underline-offset: 3px;
  text-decoration-line: underline !important;
    align-items: center;
    padding: 12px 24px !important;
    gap: 8px;
    background: transparent !important;
    border-radius: 16px !important;
    font-size: 14px !important;
    line-height: 19px;
    display: flex;
    color: #653279 !important;
    border: none !important;
    font-weight: 900;
}

.btn{
border: none;

}

.buttonCategoryModal{
  height: 40px;
  background: #FFFFFF;
  border: 2px solid #EEEEEE;
  border-radius: 24px;
  margin-right: 5px;
  font-size: 14px;
  min-width: 90%;
  font-weight: 700;
  padding:8px 16px;
}


.buttonFilter{
  height: 40px;
background: #FFFFFF;
border: 2px solid #EEEEEE;
border-radius: 24px;
margin-top: 32px;
}

.imgFilter{
  align-items: right !important;
}

.mobileFlexWrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.vl {
  border-left: 1px solid #DDDDDD;
  height: 40px;
  width: 1px !important;
  margin:0px 16px;
  padding: 0px 0px !important;
}
.contenitoreCorpo .card-title-tours span{
font-weight: 500;
font-size: 14px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #FFFFFF;
}
.contenitoreCorpo .card-text-tours{
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  color: #25182C;
  margin-bottom: 0px;

  }

  .marginTop24{
    margin-top: 24px !important;
  }

  hr.solid {
    border-top: 3px solid #bbb;
  }

  .labelClassDestinazione{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    font-feature-settings: 'calt' off;
    color: #25182C; 
    width: 112px;
  }
  .marginLeft8{
    margin-left: 8px;
  }
  .marginLeft16{
    margin-left: 16px;
  }
  .input-modal-destination{
    background: #FFFFFF;
    padding-left: 8px;
border: 1px solid #DDDDDD;
border-radius: 12px;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
  }

/*.modalCorpoDestinazione .modal-dialog{
  
}
.modalCorpoDestinazione .modal-footer{
display: inline;
}*/

.modalCorpoDestinazione{
  position: absolute;
flex-direction: column;
z-index: 3;
width: 320px !important;
top:40px;
left:125px;
margin-top: 16px;
background-color: white;
border: 1px solid #DDDDDD;
border-radius: 10px;
padding: 15px 15px;
filter:drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16));
}
.modalCategorie{
  left:0px; 
  filter:drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16));
}


.positionRelative{
position: relative;
}

.marginTop80{
margin-top: 80px !important;
}

.marginBottom160{
  margin-bottom: 160px !important;
}

.buttonSalvaModal{
justify-content: center;
align-items: center;
/*padding: 12px 24px !important;*/
gap: 8px;
width: 83px;
height: 43px;
background: #653279 !important;
border-radius: 16px !important;
font-size: 14px !important;
line-height: 19px;
display: flex;
color: #FFFFFF;
}
.buttonCancellaModal{
  justify-content: center;
  align-items: center;
  padding: 12px 24px !important;
  gap: 8px;
  width: 83px;
  height: 43px;
  background: transparent !important;
  border-radius: 16px !important;
  font-size: 14px !important;
  line-height: 19px;
  display: flex;
  color: #DDDDDD !important;
  border: none !important;
  }

  .deactivate{
    border-bottom: #FFFFFF;
  }


.buttonPrezzo{
  width: 99px !important;
  height: 40px;
    background: #FFFFFF;
    border: 2px solid #EEEEEE;
    border-radius: 24px;
    margin-right: 5px;
}
.contenitoreCorpo a{
  text-decoration: none;
}
.detailCard .card-img-top{
  height: 327px;
  width: 100%;
}
.detailCard .container-cardDestination {
  width: 100%;
}
/*.modal{
  top: var(--top-modal) !important;
  left: var(--left-modal) !important;
}*/

.buttonViaggiatori{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.destinationDetail{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.hero_section.destinazione {
  height: 60vh;
  background-size:cover;
  background-repeat: repeat, no-repeat;
}

.flexDestination{
  display: flex;
  height: 100%;
  align-items: flex-end;
  padding-bottom: 16px;
  justify-content: flex-start;

}

.listDestination{
  display: grid;
justify-content: start;
grid-template-columns: repeat(2, 1fr);
gap: 40px;
}

.grid-tour{
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(4, 1fr);
}

.cardTour{
  width: 90% ;
  height:493px;
  border:none !important;
}

.filterMobile{
  display: none;
}

.modalFilter{
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #FFFFFF;
}

.modalFilter .modal-content {
  border:none;
}

@media screen and (max-width: 479px) {
  .destinationHome,.offertaHome,.contenitore-Perche,.container-Tours,.percheHome,.destinationDetail{
    padding: 16px;
  }
 .hero_section.destinazione{
  height:230px
 }

  .listDestination{
    grid-template-columns: repeat(1, 1fr);
  }
 
  
  .offertaHome .flexColum, .contenitore-Perche .flexColum{
    flex-direction: column;
  }

  .cardCosaOffriamo{
    width: 100%;
  }
  .divButton{
    display: flex;
    justify-content: start;
    flex-direction: row;
  }

  .listDestination .card{
    width: 100%;
    height: 178px;
    border-radius: 20px;
  }
  .listDestination .container-cardDestination{
  height: 178px;
  }
  .listDestination .card-img-top{
    height: 178px !important;
    object-fit: cover;
  }
  .grid-tour{
    grid-template-columns: repeat(1, 1fr);
  }
  .cardTour{
    width: 100%;
  }
  .filterMobile{ 
  display: inline;

  }
  .filterWeb{
    display: none;
  }

 .imgButton{
  margin-left: 0px;
 }

 .buttonMobile .imgButton{
  object-fit: cover;
  height: 24px;
 }

  .filterMobile .modal-dialog{
    margin:0px;
  }

  .destinationDetail .card-img-top{
    height: 230px;
  }
  
  .destinationTitle{
    font-size:32px;
  }
  .destinationSubTitle{
    font-size: 16px;
  }
  .destinationTitlepage{
    font-size: 12px;
  }
  .title-head{
    font-size: 32px;
  }
  .destinationDetail .trip-button {
    font-weight: 700;
font-size: 16px;
line-height: 24px;
  }

  .destinationDetail .titleCorpo{
    font-size: 24px;
  }
  .destinationDetail .subtitleCorpo{
    font-weight: 500;
font-size: 16px;
line-height: 150%;
  }
  .contenitoreCorpo  .card-text-tours {
    font-size: 18px;
}
.card-text-price{
  font-size: 16px;
}
.descrizionetour {
  font-size: 16px;
}
.trip-button img{
  height: auto;
  }

  .destination{
    margin-top: 48px;
  }
  .listDestination {
    gap: 20px;
}

}