.tourHead{
  min-height: 579px;
}

.imgHeadTour{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.contenitoreCorpoTour{
  margin-top: 62px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.titoloTour{
  font-weight: 700;
  font-size: 45.7191px;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;
  color: #25182C;
}


.card-title-tours span{
font-weight: 500;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #FFFFFF;
}

.durataTour{
  float:left;
  margin-right: 60px;
}
.subtitleTour{
  font-weight: 500;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
}

.marginTop30{
  margin-top: 30px !important;
}
.marginRight8{
  margin-right: 8px;
}
.descrizionetour{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 160%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
margin-top: 30px !important;
}



.titleAccessibilit{
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #25182C;
  margin-top: 64px !important;
  margin-bottom: 8px;
  padding: 0px 0px !important;
  margin-left: 0px !important;
}

.subtitleAccessibilit{
  font-weight: 500;
font-size: 16px;
line-height: 150%;
font-feature-settings: 'calt' off;
color: #55575B;
padding: 0px 0px !important;
margin-left: 0px !important
}

.rowAccessibilit .col-6{
margin-left:0px !important;
padding: 0px;
}

hr.solid {
  width: 100%;
  border-top: 1px solid rgb(0 0 0 / 50%);
}
.bottomCard{
  width: 100%;
}
.boxPrenotazioni{
background: #FFFFFF;
border: 1px solid #DDDDDD;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 16px;
}
.titoloBoxPrenotazioni{
  font-weight: 700;
font-size: 24px;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
margin-bottom:8px;
}
.subtitleBoxPrenotazioni{
  font-weight: 500;
font-size: 16px;
line-height: 150%;
font-feature-settings: 'calt' off;
color: #55575B;
}
.prezzoBoxPrenotazioni {
  font-weight: 700;
font-size: 38.0992px !important;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
position: relative;
}
.prezzoBoxPrenotazioni span{
  font-weight: 500;
font-size: 19.0496px;
}
.subtitlePrezzo{
  font-weight: 500;
font-size: 14px;
line-height: 140%;
display: flex;
align-items: center;
color: #55575B;
position: absolute;
top:50px;
}

.contenuto{
  margin:24px 24px;
}
.box-prenotazione-input{
  width: 100%;
  height: 45.86px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 16px;
  margin:8px 0px 20px 0px;
  padding: 10px;
}
.personeDisabilitBox{
font-weight: 700;
font-size: 17.1446px;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
margin-bottom:24px;
}
.btn-prenotazione{
  font-size: 16px;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 70px;
  background: #653279;
  border-radius: 20px !important;
  padding: 24px 16px !important;
  color:#FFFFFF;
}
.testoTour{
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.boxPersone{    
position: absolute;
flex-direction: column;
min-width: 326px;
width: 327.65px;
z-index: 3;
margin-top: 16px;
background-color: white;
border: 1px solid #DDDDDD;
border-radius: 10px;
padding: 15px 15px;
}
.moreInfo-button{
  width: 235.05px;
height: 56px;
  background: #FFFFFF;
border: 2px solid #EEEEEE;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 16px;
margin-top:32px;
}
.moreInfo-button a,.moreInfo-button a:hover{
  color: #25182C;
}


.moreInfo-button img{
  height: 11px;
  width: 12px;
  margin-left: 10px;
}

.riga{
  width: 100%;
}

.spanPrice{
  margin-right:5px;
}
.buttonBoxPerson{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border: 2px solid grey;
    
}

.buttonBoxPerson:hover,.buttonBoxPerson:not(:disabled){
  border: 2px solid grey;
}
.right-button:not(:disabled),.buttonSlider:not(:disabled){
  border: 2px solid #EEEEEE;
  box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
}
.selectCorpo:not(:disabled){
border: 2px solid #EEEEEE;
}

.titleBoxPersone{
  line-height: 22px;
  font-weight: 900;
  font-size: 16px;
  color: #25182C;
}
.subtitleBoxPersone{
  line-height: 22px;
  font-weight: 900;
  font-size: 16px;
color:gray;
}
.labelBoxPerson{
margin-right: 10px;
}
.labelBoxPerson{
  margin-bottom: 0px;
  margin-left: 20px;
  margin-right: 20px;
}

.btn-boxPerson{
  align-items: center;
  gap: 8px;
  width: 300px;
  height: 35px;
  background: #653279;
  border-radius: 20px !important;
  padding: 5px 16px !important;
  color: #FFFFFF;
}

.button-row{
  padding-left: 0px !important;
}

.contenitoreCorpoTour li{
  text-align: left;
}
.positionRelative{
  position: relative;
}

.buttonHeadTour{
  position:absolute;
  left: 40px;
bottom: 40px;
background: #FFFFFF;
border: 2px solid #EEEEEE;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 16px;
width: 201px;
height: 64px;
}
.margintop10{
  margin-top: 10px !important;
}

.imgItinerario{
  width: 97%;
  margin-top: 10px;
  height: 360px;
  object-fit: cover;
  border-radius: 20px;
}

.filterClass{
  font-weight: 700;
font-size: 16px;
line-height: 24px;
padding-top: 16px;
}

.itinerarioMulty{
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
}

.titleItinerario{
  font-weight: 700;
font-size: 23px;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
margin-top: 10px !important;
margin-bottom: 8px;
padding: 0px 0px !important;
margin-left: 0px !important;
}

.hotelName{
  font-weight: 500;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
margin-left: 16px;
}

.cardItinerario{
  margin-bottom: 16px;
}

.marginTop10{
  margin-top:10px !important;
}

.tourHead{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.tourDetail{
  margin-bottom: 120px;
}
.tourHeadMobile{
  display: none;
}
.tour-displayFlex{
  display:flex;
  flex-direction: row;
}
.prenotazioniTourMobile{
  display: none;
}

.rowHeadTour{
    height: 580px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }

  .colTour{
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-between;
  }
.row-2{
  height: 48%;
  width: 100%;
}
.row-3{
  height: 49%;
  display: flex;
  justify-content: space-between;
}
.colTour-1{
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-flex{
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  }

@media screen and (max-width: 479px) {
 
  
.tourHead,.prenotazioniTour{
  display: none;
}
.tourDetail,.contenitoreCorpoTour{
  padding: 10px;
}
.rowAccessibilit{
  padding: 10px;
}
.tourHeadMobile{
  display: inline;

  height: 230px;

}
.titoloTour{
  font-size: 32px;
}
.imgHeadTour{
  height: 230px;
  object-fit: cover;
}
.tour-displayFlex{
  display:flex;
  flex-direction: column;
}
.prenotazioniTourMobile{
  display: inline;
  position:fixed;
  bottom:0;
  left: 0;
  z-index: 100;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 18px 40px -12px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 8px 0px 21px 0px;
  border-top: 1px solid #EFEFEF;
}

.buttonContainerMobile .btn-prenotazione{
  font-size: 14px;
  padding: 16px!important;
  height: auto;
 
}
.prenotazioniTourMobile .prezzoBoxPrenotazioni{
  width: 40%;
}
.buttonContainerMobile{
  width: auto;
  margin-right: 8px;
}

.testoTour{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.prezzoBoxPrenotazioni .riga, .prezzoBoxPrenotazioni .subtitlePrezzo{
  font-size: 14px;
  margin-left: 10px;
}
.modal-dialog .boxPrenotazioni{
  border:none;
  box-shadow: none;
}
.modal-dialog .boxPrenotazioni .contenuto{
  margin:0px 0px;
}
.wizard .contenuto{
  margin: 56px 0px 0px 0px;
}

.modalRegistrati .modal-content{
  border: none;
}
.buttonHeadTour {
  left: 8px;
  bottom: 8px;
  width: auto;
  padding:8px 16px;
  font-weight: 700;
font-size: 14px;
line-height: 24px;
height: auto;
}

.rowAccessibilit .titleAccessibilit{
  font-size: 20px;
}

.titleAccessibilit{
  margin-top: 20px !important;
}

.durataTour{
  float: none;
}

.rowAccessibilit .col-6{
  width: 100%;
}

.cardItinerario .col-4,.cardItinerario .col-8{
  width: 100%;
  margin-bottom: 16px;
}

.imgHotel,.moreInfo-button{
  margin-top: 16px;
}
.contenitoreCorpoTour{
  margin-top: 24px;
}
.contenitoreCorpoTour .card-title-tours{
  padding: 2px 10px;
}


}