.btn-language{
    border:none !important;
}
.navbar-cosy{
  
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    margin-top: 10px;
    height: 115.75px;
    background-color: #FFFFFF;
}

.selectecMenu{
    border-bottom: 2px solid #FFAA4D;
}   

.language{
    width: 24px;
    height: 24px;
}
.hide-language{
    display: none;
}

li{
    margin-right: 10px;
    text-align: right;
}
.space{
    /*width: 22%;*/
}
.navbar-brand{
    margin-left: 10px;
}

.voceMenu a,.voceMenu a:hover{
    color: #25182C;
    text-decoration: none;
}

.btn-cosy{
    background: #653279 !important;
    color:#FFFFFF !important;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 20px !important;
}

.btn-cosy-white{
    background: #FFFFFF !important;
border: 2px solid #EEEEEE !important;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05) ;
border-radius: 20px !important;

}

.btn-lenguage{
    display: none !important;
}

.voceMenu{
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-right: 32px;
}
.navbar-nav .voceMenu{
height: 30px;
}
.logoSidebar{
    width: 120px;
    height: auto;
}
.titleDestination{
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: 0.01em;
color: #25182C;
}

.spanLanguage{
font-weight: 700;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
margin-right: 8px;
}
#navbarNavDropdown{
    justify-content: flex-end;
}
.paddingBottom16{
    padding-bottom: 16px;
}

.modallinkHeader{
    position: absolute;
  flex-direction: column;
  z-index: 3;
  width: 320px !important;
  top:70px;
  margin-top: 16px;
  background-color: white;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 24px 24px 16px 24px;
  filter: drop-shadow(0px 15px 32px rgba(0, 0, 0, 0.05));
  }

.headerProfileLink{
font-weight: 400;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
text-decoration: none;
}
.navbar-cosy hr.solid {
    border-top: 3px solid #bbb;
    width: 90%;
    display: none;
}
.languageMobileShow{
    display: none !important; 
}

.navbar-cosy .btn-cosy, .navbar-cosy .btn-cosy-white{
    padding: 16px 24px;
}

.vl-mobile{
   display: none; 
}

.flex-container-end{
    display: flex;
    justify-content: end;
}

hr.solid-menu{
    border-top: 3px solid #bbb;
    width: 90%;  
}

.navbar-nav{
    align-items: center;
    display: flex;
}
button{
    font-weight: 700 !important;
}
.navbar-toggler{
    border: none !important;
}
.navbar-toggler:focus{
    box-shadow:none !important;
    border: none !important;
}


@media screen and (max-width: 479px) {
.logoSidebar{
    padding: 10px;
    width: 75px;
    object-fit: cover;
}
.navbar-cosy .btn-cosy-white{
    color: #653279;
    border: none !important;
    box-shadow: none;
}

.navbar-cosy .btn-dark{
    background: rgba(101, 50, 121, 0.1) !important; 
    color: #653279 !important;
    border-color: transparent;
}

.navbar-cosy{
    height: 60px;
}
.navbar-toggler{
    margin-right: 10px;
}
#navbarNavDropdown{
    z-index: 100;
}   
.navbar-nav{
    background-color: #FFFFFF;
    align-items: center;
    z-index: 100;
    height:auto;
}
.languageMobile,.navbar-nav .vl, .navbar-nav hr.solid{
    display: none;
}
.btn-cosy-white, .btn-cosy{
width:300px;
padding:10px;
margin-bottom: 10px;
}
.languageMobileShow{
    display: flex !important;
}
.navbar-cosy hr.solid {
    border-top: 3px solid #bbb;
    width: 90%;
    display: inline;
}

.navbar-cosy hr.solid100 {
    border-top: 3px solid #bbb;
    width: 100%;
    display: inline;
}

.vl-mobile{
        border-left: 1px solid #DDDDDD;
        height: 40px;
        width: 1px !important;
        margin:0px 16px;
        padding: 0px 0px !important;
        display: inline;
}

.voceMenu{
    margin-bottom: 40px;
}

}


@media screen and (max-width: 767px) {
    .voceMenu{
        margin-bottom: 40px;
    }
    .logoSidebar{
        padding: 10px;
    }
    .navbar-toggler{
        margin-right: 10px;
    }
    #navbarNavDropdown{
        z-index: 100;
    }   
    .navbar-nav{
        background-color: #FFFFFF;
        align-items: center;
        z-index: 100;
        height:auto;
    }
    .languageMobile,.navbar-nav .vl, .navbar-nav hr.solid{
        display: none;
    }
    .btn-cosy-white, .btn-cosy{
    width:300px;
    padding:10px;
    margin-bottom: 10px;
    }
    .languageMobileShow{
        display: flex !important;
    }
    .navbar-cosy hr.solid {
        border-top: 3px solid #bbb;
        width: 90%;
        display: inline;
    }
    .navbar-cosy hr.solid100 {
        border-top: 3px solid #bbb;
        width: 100%;
        display: inline;
    }
    
    .vl-mobile{
            border-left: 1px solid #DDDDDD;
            height: 40px;
            width: 1px !important;
            margin:0px 16px;
            padding: 0px 0px !important;
            display: inline;
    }
    
    }

    
    @media screen and (max-width: 991px) {
        .voceMenu{
            margin-bottom: 40px;
        }
        .logoSidebar{
            padding: 10px;
        }
        .navbar-toggler{
            margin-right: 10px;
        }
        #navbarNavDropdown{
            z-index: 100;
        }   
        .navbar-nav{
            background-color: #FFFFFF;
            align-items: center;
            z-index: 100;
            height:auto;
        }
        .languageMobile,.navbar-nav .vl, .navbar-nav hr.solid{
            display: none;
        }
        .btn-cosy-white, .btn-cosy{
        width:300px;
        padding:10px;
        margin-bottom: 10px;
        }
        .languageMobileShow{
            display: flex !important;
        }
        .navbar-cosy hr.solid {
            border-top: 3px solid #bbb;
            width: 90%;
            display: inline;
        }
        .navbar-cosy hr.solid100 {
            border-top: 3px solid #bbb;
            width: 100%;
            display: inline;
        }
        
        .vl-mobile{
                border-left: 1px solid #DDDDDD;
                height: 40px;
                width: 1px !important;
                margin:0px 16px;
                padding: 0px 0px !important;
                display: inline;
        }
        
        }
        