.virtualHead{
  margin-top:100px;
}
.titlePage{
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2em;
  font-feature-settings: 'calt' off;
  color: #25182C; 
  text-transform: uppercase
}

.titleBig{
  font-weight: 700;
font-size: 48px;
line-height: 120%;
text-align: center;
color: #25182C;
}
.subtitleBig{
font-weight: 400;
font-size: 18px;
line-height: 150%;
text-align: center;
font-feature-settings: 'calt' off;
color: #25182C;
}
.positionRelative{
  position: relative;
}
.paddingLeftRight0{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.imgVideo{
  height: 440px;
  width: 95%;
  border-radius: 20px;
  object-fit: cover;
}

.container-cardvideo {
  top: 0;
  background-color: #25182c69;
  width: 95%;
  position: absolute;
  z-index: 2;
  height: 440px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgPlay{
  position: absolute;
  top: 46%;
  left: 46%;
  z-index: 4;
  height: 56px;
  width: 56px;
  object-fit: cover;
}

.img360{
  position: absolute;
  z-index: 4;
  top: 32px;
  right: 32px;
  height: 31px;
  width: 48px;
  object-fit: cover;
}

.none{
  display: none;
}

.containerVirtual{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top:30px;
}

.titoloVideo{
  position: absolute;
  bottom:48px;
  left:27px;
  font-weight: 700;
font-size: 28px;
line-height: 130%;
color: #FFFFFF;
}
.sottotitoloVideo{
  position: absolute;
  bottom:24px;
  left:27px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: 'calt' off;
  color: #FFFFFF;
  
}
.chiSiamoHead{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 64px;
}

.chiSiamoText{
  width: 580px;
}
.imgChisiamo{
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}
.imgClass{
  width: 100%;
 
}

.marginTop50{
  margin-top: 50px !important;
}

.marginTop20{
  margin-top: 20px !important;
}

.marginBottom50{
  margin-bottom: 50px !important;
}

.titoloChiSiamoBox {
font-weight: 700;
font-size: 24px;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
}
.subtitoloChiSiamoBox{
  font-weight: 400;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
}
.section-blue {
  min-height: 550px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #13042a;
}


.container-cardChiSiamo {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.grid {
  position: relative;
  bottom: 120px;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
 
  -ms-flex-pack: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #fff;
}

.box .title{
  font-weight: 700;
font-size: 24px;
line-height: 130%;
color: #25182C;
}
.box .subtitle{
  font-weight: 400;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
}
.titleSection, .titleSectionBig{
max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
.titleSection{
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.2em;
  font-feature-settings: 'calt' off;
  color: #25182C;
  text-transform: uppercase;
}

.titleSectionBig{
  
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #25182C;
  display: flex;

}

.chiSiamoTextBig{
  max-width: 514px;
}
.content_wrapper.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-child-half {
  width: 40%;
}

.flex-child-half.right {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding-right: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

  .p {
    font-size: 16px;
    line-height: 150%;
  }
  
  .bodyfede {
    font-family: Satoshi, sans-serif;
    color: #25182c;
  }
  
  .label {
    letter-spacing: 5px;
  }
  .modalChiSiamo{
    top:20% !important;
  }
  .h3 {
    margin-top: 8px !important;
    margin-bottom: 16px !important;
    font-size: 32px !important;
    line-height: 150% !important;
    font-weight: 700 !important;
  }
  
  .half-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 48%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .img-block {
    width: 100%;
    height: 200px;
    margin-bottom: 40px;
    border-radius: 20px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .img-block._2 {
    height: 280px;
    object-fit: cover;
  }
  
  .img-block._3 {
    height: 90%;
    object-fit: cover;
  }
  
  .shape-orange {
    position: absolute;
    left: -19%;
    top: -13%;
    right: auto;
    bottom: auto;
    z-index: -1;
    overflow: hidden;
  }
  .marginTopBottom{
    margin:140px 0px;
  } 

  .marginTopBottomAuto{
    max-width: 1200px;
    margin-top:50px;
    margin-bottom: 80px;
    margin-left:  auto;
    margin-right: auto;
  }

  .section3{
    max-width: 1200px;
    margin-top:160px;
    margin-left:  auto;
    margin-right: auto;
  }
  .shape-orange.purple {
    left: 18%;
    top: -15%;
  }

  .frase{    
  width: 100%;
  display: inline;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
}

.memberTeams{
background: #F4F4F4;
padding: 140px;
}
.memberTeams .chiSiamoHead{
  padding: 0;
  margin-bottom: 64px;
}
.imgMembro{
  background: #F4F4F4;
border-radius: 140px;
width: 120px;
height: 120px;
margin-top:30px;
}

.titleCardMember{
font-weight: 700;
font-size: 24px;
line-height: 130%;
text-align: center;
color: #25182C;
}

.cardMember{
width: 95%;
background: #FFFFFF;
border: 1px solid #DDDDDD;
border-radius: 20px;
min-height: 416px;
}

.marginMember{
margin-top: 20px;
margin-bottom: 20px;
}

.buttonCardMember{
  background: #653279 !important;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 20px !important;
color:#FFFFFF !important;
padding: 16px;
}

.modalChiSiamo .modal-dialog{
  max-width: 795px;
}

.modalChiSiamo .modal-content,.modalChiSiamo .modal-body{
   margin-left: 25px;
  margin-right: 25px;
}


.modalImg{
  margin-bottom: auto;
  margin-top: auto;
}

.modalVirtual{
  position: absolute;
    flex-direction: column;
    z-index: 200;
    width: 320px !important;
    margin-top: 40px !important;
    background-color: white;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 15px 15px;
}

.marginBottom24{
  margin-bottom: 24px !important;
}
.flex-content-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.flex-content-row{
  display: flex;
  flex-direction: row;
}

.content-box-chiSiamo{
  width: 90%;
}
.roleCardMember{
  text-transform: uppercase;
  color: #55575B;
  font-size: 14px;
  text-align: center;
}
.modal-body .titleCardMember{
  text-align: left;
}
.marginLeft{
  margin-left: 5%;
}

.sectionChiSiamo2{
  display: flex;
  flex-direction: column;
}

.duration{  
  font-size: 16px;
position: absolute;
top: 16px;
right: 45px;
color: white;
z-index: 50;
min-width: 60px;
border: 1px solid #FFAA4D;
border-radius: 16px;
padding: 1px 10px;
}

.tre{
  width: 100%;
}
.imgVideo3{
  width: 100%;
}

.virtual3{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mobileVirtual{
  --bs-gutter-x: 0 !important;
}

@media screen and (max-width: 479px) {
.containerVirtual .row{
  flex-direction: column;
  justify-content: flex-start;
  gap: 47px;
}
.container-cardvideo{
  height: 377px;
  width: 100%;
}
.virtualMobile{
  margin-left: 0px !important;
 
}
.img360{
 
  top: 20px;
  right: 20px;
 
}



.containerVirtual,.virtualHead{
  padding: 10px;
}
.containerVirtual .col-6,.containerVirtual .col-4{
  width: 100%;
}
.imgVideo{
  width: 100%;
  height: 377px;
}
.titoloVideo{    
bottom: 30%;
}

.chiSiamoHead, .section-blue,.section2,.section3,.container-cardChiSiamo{
  padding:10px;
}

.titlePage{
  font-size: 12px;
}
.titleBig{
  font-size: 32px;
}
.frase,.container-cardChiSiamo .h3{
  font-size: 24px;
}
.subtitleBig{
  font-size: 16px;
}
.imgChisiamo{
  padding: 10px;
}
.flex-content-between{
  flex-direction: column;
}
.content-box-chiSiamo{
margin-bottom: 32px;
}
.titleSection{
  font-size: 12px;
}

.titleSectionBig{
  font-size:24px;
  text-align: center;
}
.section-chiSiamo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}
.section-blue .grid{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section2.marginTopBottom{
  margin: 80px 0;
}
.section2 .flex-child-half,.section3 .flex-child-half{
  width: 100%;
}
.sectionChiSiamo2{
  display: flex;
  flex-direction: column;
}
.marginTopBottomAuto{
  margin-top: 0px;
}
.content-box-chiSiamo{
  width: 100% !important;
}
.memberTeams .titleBig{
  font-size: 24px;
}

.memberTeams .titlePage{
  font-size: 12px;
}
.cardMember{
  width: 100%;
}
.modalChiSiamo .modal-content{
  margin-right: 0px;
  margin-left: 0px;
}
.modal-body .titleCardMember{
  text-align: left;
  font-size: 20px;
}
.modal-body .roleCardMember{
font-size: 14px;
text-align: left;
}
.modal-body .flex-content-row{
flex-direction: column;
}

.duration{
  right: 25px;
}

.mobileVirtual{
  padding-left: 10px;
  padding-right: 10px;
}

.cifra{
  font-weight: 400 !important;
font-size: 14px !important;
line-height: 19px;
color: #25182C !important;
}
.spanPrice{
  font-weight: 100 !important;
  font-size: 12px !important;
  color: #25182C !important;
}

}



@media screen and (max-width: 991px) {
  .imgChisiamo,.section-chiSiamo, .grid,.section2,.sectionChiSiamo2,.section3{
    padding: 10px;
  }
  .memberTeams{
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-box-chiSiamo{
    width: 32%;
  }
  .container-cardChiSiamo .half-column{
    flex-direction: column;
   }
  .container-cardChiSiamo .grid .box{
    height: auto;

  }
  .titoloVideo{
    font-size: 22px;
    bottom:70px;
  }
  
  }
  

  .playerWrapper{
    height: 100%;
    width: 100%;
}

.customVideoPlayer{
    padding-top: 100px;
    color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.video-js .vjs-tech{
  position: absolute;
  top: 0;
  height: 100%;

}

.videoPlayer{
  background: #000000de ;
  
  width: 100%;
  height: 100vh;
  z-index: 2000;
  position:fixed;
  top:0;
  left:0;
  
}
.buttonClose{
  position: absolute;
  top:24px;
  right:24px;
}



.videoContainer{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-js{
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*.video-js .vjs-big-play-button{
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative !important;
  top:auto !important;
  left: auto !important;
}*/