.bodyContatti {
  font-family: Satoshi, sans-serif;
  color: #25182c;
}


.bodyContatti .hero_section.contatti {
  height: 60vh;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('../images/contatti-hero-image.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/contatti-hero-image.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  -webkit-filter: brightness(151%) contrast(114%) hue-rotate(21deg);
  filter: brightness(151%) contrast(114%) hue-rotate(21deg);
}

.label.white {
  color: #fff;
}

.label.white.medium {
  font-weight: 500;
}
.p.white.big {
  font-size: 16px;
}
.p.white {
  color: #fff;
}

.bodyContatti .content_wrapper.flex.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}


.sectionContatti{
  padding-top: 80px;
    padding-bottom: 0px;
}

.bodyContatti .h2 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 120%;
  font-weight: 900;
}

.linkImageLittle{
  text-decoration: underline;
  font-weight: 600;
  color:#653279
}

.bodyContatti .h2.white {
  color: #fff;
}

.bodyContatti .titolo {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
  font-size: 32px !important;
  line-height: 150% !important;
  color: #25182c;
  font-weight: 700 !important;
}

.bodyContatti ._2-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.bodyContatti ._2-col.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bodyContatti .form-block {
  margin-bottom: 0px;
  padding: 40px;
}

.bodyContatti .input-field {
  min-height: 48px;
  border: 1px solid #ddd;
  border-radius: 16px;
  width: 100%;
  padding: 8px 12px;
}

.bodyContatti .input-field.area {
  min-height: 200px;
  margin-bottom: 24px;
  padding: 16px;
}

.bodyContatti .input-field-wrapper {
  width: 100%;
  margin-top: 20px;
}

.bodyContatti .input-field-wrapper.half {
  width: 48%;
}

.bodyContatti label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.bodyContatti .button-primary {
  padding: 24px 32px;
  border-radius: 20px;
  background-color: #653279;
  font-size: 16px;
  font-weight: 500;
  border: none;
  color: #fff;
}

.bodyContatti .contact-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bodyContatti .contact-wrapper.margin {
  margin-top: 32px;
  margin-bottom: 24px;
}

.bodyContatti .icon-wrapper.margin {
  margin-right: 16px;
}

.bodyContatti .text-link {
  color: #653279;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.bodyContatti .contattaci-wrapper {
  margin-bottom: 40px;
}

.bodyContatti .social-icons-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bodyContatti .social-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48px;
  height: 48px;
  margin-right: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #ddd;
  border-radius: 50px;
  box-shadow: 0 1px 32px 0 rgba(0, 0, 0, 0.05);
}

.bodyContatti .form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bodyContatti .divider {
  width: 100%;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #ddd;
}

.bodyContatti .author-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.textContact{
  font-weight: 400;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
}

.containerContatti{
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

.bodyContatti .flex-child-half._40 {
  width: 100%;
  margin-bottom: 64px;
}

.bodyContatti .flex-child-half._40.relative.form {
  top: 0px;
  width: 90%;
}

.bodyContatti .form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.bodyContatti .flex-child-half._40.relative.form {
  top: -140px;
  width: 50%;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 1px 32px 0 rgb(0 0 0 / 5%);
}
.bodyContatti .flex-child-half._40.relative {
  position: relative;
  top: -217px;
  background-color: #fff;
}

.bodyContatti .flex-child-half._40 {
  width: 40%;
}

.contattiLabel{
  font-weight: 600;
  text-transform: uppercase;
}
@media screen and (max-width: 479px) {
.bodyContatti .flex-child-half._40{
  width: 100%;
}
.bodyContatti .flex-child-half._40.relative.form{
  top: 0px;
  width: 100%;
}
.containerContatti .white.big, .containerContatti .h2.white{
  text-align: center;
}

}