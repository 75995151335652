a{
  text-decoration: none !important;
}
button{
color: #25182C;

}
.footerTablet{
  display: none;
  }
.container-home{
  color: white;
  position: relative;
  text-align: center;
  width: 100% !important;
}
  
  /* Bottom left text */
   .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  .control-dots{
    width: 22% !important;
  }
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  /* Centered text */
  .centered {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

.title-slider-home{
font-weight: 700;
font-size: 80px;
line-height: 120%;
text-align: center;
letter-spacing: 0.01em;
color: #FFFFFF;
  }

.testo-slider-home{
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    font-feature-settings: 'calt' off;
    color: #FFFFFF;
}

.inputWrapp{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.button-slider-home{
margin-top:24px;
width: 186px;
height: 72px;
background: #FFFFFF;
border: 2px solid #EEEEEE;
border-radius: 24px;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
color: #25182C;
}
p.clear {
    clear: left;
  }

.destinationHome{
margin-top: 172px;
margin-left: auto;
margin-right: auto;
max-width: 1200px;
}

.right-button{
 
 background: #FFFFFF;
border: 2px solid #EEEEEE;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 24px;
width: 119px;
height: 72px;
}
.card{
width: 100%;
    height: 215px;
border-radius: 20px !important;
object-fit: cover;
}

.title-card-home{
  font-size: 28px;
  text-align: center;
  color: #FFFFFF;

}
.offertaHome{
 margin-top: 160px;
 max-width: 1200px;
 margin-left: auto;
 margin-right: auto;
}
.titleOfferta{
  width: 100%;
  font-weight: 700;
font-size: 40px;
line-height: 120%;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
color: #25182C;

}
.subtitle-card-home{
  width: 100%;
font-weight: 400;
font-size: 18px;
line-height: 150%;
text-align: center;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
}
.offertaHome .card{
  border:none !important;
}
.offertaHome .btn,.offertaHome .btn:hover{
padding: 16px 24px;
gap: 8px;
width: 140px;
height: 56px;
background: #653279;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 20px;
border: none;
}
.card-title{
  font-weight: 700;
font-size: 28px;
line-height: 130%;
color: #25182C;
margin-top: 32px;
}

.card-text{
  margin-top:24px;
  font-weight: 400;
font-size: 18px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
}
.percheHome{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  margin-bottom: 150px;
}
.img-perche{
  width:75%;
  z-index: 100;
}

.card-img-top{
  height: 215px;
  object-fit: cover;
  border-radius: 20px;
}
.cosaOffriamoImg{
  height: 290px;
}

.container-cardDestination{
  background-color: #25182c69;
  width: 100%;
  position: absolute;
  z-index: 2;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.destinationHome .card{
  border:none !important;
  border-radius: 0px;
}
.card-img-top{
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.componente-perch{
  width: 100%;
  background: #25182C;
}
.titolo-perche{
width: 400px !important;
font-weight: 700;
font-size: 40px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: 0.01em;
color: #FFFFFF;

}
.perche-testi{
  margin-top: 48px !important;
}

.titolo-interno-perche{
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.testo-perche{
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: #FFFFFF;
}

.slick-slider {
    margin: 30px auto 50px;
    width: auto;
}

.slick-prev {
  background: black;
}

.slick-next {
  background: black;
}
.slick-dots li {
  margin: 0 16px;
}
.slick-dots li button:before {
  content: " ";
  background: #D8D8D8;
  opacity: 1;
  height: 4px;
  width: 40px;
}
.slick-dots li.slick-active button:before {
  content: " ";
  background: #0080AD;
  opacity: 1;
  height: 4px;
  width: 40px;
}


.card-title-tours{
  padding: 2px 10px;
  gap: 10px;
  height: 28px;
  background: #3E67D0;
  border-radius: 19px;
  margin-bottom: 16px;
  font-weight: 600;

}
.card-body{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dicono .card-body{
  padding: 32px !important; 
}

.card-title-tours span{
font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: #FFFFFF;
  
}
.card-text-tours{
font-weight: 700;
font-size: 22px;
line-height: 130%;
color: #25182C;
text-align: left;
}
.card-text-price{
  font-weight: 400;
font-size: 16px;
line-height: 130%;
color: #25182C;
text-align: left;
}

.container-Tours{
  margin-top:160px;
  max-width: 1140px;
  margin-left:auto;
  margin-right:auto;

}

.partnerHome{
  margin-top:160px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

.imgPartner{
  width: auto;
  height: 120px;
}

.fontSize24{
font-size: 24px;
}

.titleTours{

font-weight: 700;
font-size: 40px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: 0.01em;
color: #25182C;
}

.slick-prev{
  top:0px;
  left: 90%;
  margin-bottom: 10px;
  }

  .slick-next{
  top:0px;
  right:0;
  }

  .next-slick-arrow,.prev-slick-arrow{
    height: 16px;
    width: 16px;
  }

  .dicono{
height: 320px !important;
background: #FFFFFF;
border: 1px solid #DDDDDD;
border-radius: 20px;
text-align: left;
  }
.card-img-top-dicono{
  width:34px ;
  height: 25px;
  margin-bottom:32px
}
.dicono-title{
font-weight: 700;
font-size: 21px;
line-height: 140%;
display: flex;
align-items: center;
color: #25182C;
}

.card-text-dicono{
  font-weight: 400;
font-size: 16px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #55575B;
text-align: left;

}

.card-text-nome{
font-weight: 700;
font-size: 18px;
line-height: 150%;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #25182C;
text-align: left;
bottom: 4px;
position: absolute;
}

.homeImg{
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/hero_slider_1.jpg'); 
background-position: 50% 50%;
background-size: auto;
background-repeat: no-repeat;
height: 80vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
background-size: cover;
}

.homeImg2{
  background-image:linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/hero_slider_2.jpeg'); 
background-position: 50% 50%;
background-size: auto;
background-repeat: no-repeat;
height: 80vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
background-size: cover;
}

.homeImg3{
  background-image:linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/slider-3-img.jpg'); 
background-position: 50% 50%;
background-size: auto;
background-repeat: no-repeat;
height: 80vh;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
background-size: cover;
}

.linkBread{
  font-weight: 700;
font-size: 16px;
line-height: 140%;
display: flex;
align-items: center;
text-decoration-line: underline;
color: #25182C;
}

.img-newsletter{
background-image: url('../images/newsletterHome.png');
background-position: 50% 50%;
background-size: auto;
background-repeat: no-repeat;
height: 500px;
width: 100%;
border-radius: 20px;
display: flex;
flex-direction: column;
justify-content: center;
}

.text-newsletter{
font-weight: 700;
font-size: 40px;
line-height: 120%;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
color: #FFFFFF;
margin-bottom: 16px;
}

.subtitle-newsletter{
  font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #FFFFFF;
}
.form-control-detail{
background: #FFFFFF;
border-radius: 16px;
margin: 16px 16px;
padding-left: 16px;
}
.btn-newsletter{
  width: 102px;
height: 48px;
}
.btn-newsletter{
background: #FFAA4D;
border-radius: 16px;align-items: center;
gap: 8px;
border:none;
color:#FFFFFF

}

.footer{
  width: 100%;
  background: #25182C;
 
}


.logoFooter{
  width: 90px;
  height: auto;
}

.text-footer{
  font-weight: 700;
font-size: 14px;
line-height: 24px;
text-transform: uppercase;
font-feature-settings: 'calt' off;

color: #FFFFFF;
}

.subtitle-footer{
  margin-top:24px;
}
.subtitle-footer a,.subtitle-footer a:hover{

font-weight: 700;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #FFFFFF;
text-decoration: none;
}

.voidrow{
  color:transparent;
}
.text-newsletter-footer{
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  font-feature-settings: 'calt' off;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.subtitle-newsletter-footer{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  font-feature-settings: 'calt' off;
  color: #FFFFFF;
}

.button-footer-news{
  width: 86px;
  height: 48px;
  background: #FFAA4D;
border-radius: 16px;
color:#FFFFFF;
}
.input-footer{
  width: 320px;
height: 48px;
background: #FFFFFF;
border-radius: 16px;
margin-left:0px !important;
}
.separator{
margin-top: 32px;
  width:100%;
  height: 1px;
mix-blend-mode: normal;
opacity: 0.3;
border: 1px solid #FFFFFF;

/* Inside auto layout */

flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.logoFooterIcon{
  width: auto;
  height: 24px;
  margin-right:10px;
}

.testo-subfooter, .testo-subfooter a,.testo-subfooter a:hover{
  font-weight: 700;
font-size: 14px;
line-height: 150%;

letter-spacing: -0.01em;
font-feature-settings: 'calt' off;
color: #FFFFFF;
text-decoration: none;
}
.testo-coopiright{
font-weight: 300;
}
.detailCard{
  width: auto;
height: 327px;
}

  
.slick-slide {
	margin: 0 5px;
}
/* the parent */
.slick-list {
	margin: 0 -5px;
}

.buttonSlider{
  background: #FFFFFF;
border: 2px solid #EEEEEE;
box-shadow: 0px 15px 32px rgba(0, 0, 0, 0.05);
border-radius: 16px;
height: 56px !important;
    width: 56px !important;
    margin-left: 10px;
}

.buttonModalClose{
  border: none;
  background: #FFFFFF;
  height: 20px !important;
  width: 40px !important;
}

.slick-arrow{
  display: none !important;
}

.carousel-slider{
  display: flex;
    align-items: center;
}

.control-dots{
  width:100%;
}

.itinerary-wrapper li{
  text-align: left !important;
}

.contenitore-Perche{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top:160px;
  padding-bottom: 60px;
}

.home-slide-container-title{
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;

}



.control-disabled{
  display: flex !important;
  opacity: 1 !important;
}
.control-arrow{
  opacity: 1 !important;
}
marginBottom40{
  margin-bottom: 40px!important;
}

.perchèHomeImg{
  width: 100%;
  position: relative;
  bottom: 60px;

}
.marginRight60{
  margin-right: 60px;
}
.marginRight32{
  margin-right: 32px;
}
.onlyCenter{
  justify-content: center;
}

.paddingRight5{
  padding-right: 4px;
}

.cardCosaOffriamo{
  width: 45%;
  justify-content: center;
  margin: 0 24px;

}

.listDestination-home{
  margin-top: 40px;
  gap: 24px;
  display: grid;
  justify-content: start;
  grid-template-columns: repeat(3, 1fr);
}
.img-tour-home{
height: 430px;
}
.container-Tours .card{
width: 374px;
}

.containerFooter{
  padding: 100px 0px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.testo-coopiright{
  width: 28%;
}
.linkFooterWidth{
  width: 32%;
}
.linkFooterSocialWidth{
  width: 40%;
  text-align: right;
}

.cardTour .card-body{   
display: flex;
flex-wrap: wrap;
align-content: flex-start;
} 

.display-row{
  display: flex;
  flex-direction: row;
}

.carousel .control-dots {
  bottom: 32px;
}

.control-arrow {
  display: flex;
  width: 48px;
  height: 48px;
  top: auto !important;
  color: #fff !important;
  font-size: 26px !important;
  bottom: 48px !important;
  margin-top: 0 !important;
  padding: 5px !important;
  position: absolute !important;
  border: 2px solid #fff !important;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.control-next{
  right: 24px !important;
  opacity: 1;
}

.carousel .control-prev.control-arrow {
  right: 80px !important;
  left:auto !important;
  opacity: 1;
}

.blockPerche{
  width:85%;
  margin-right: 40px;
}

.spanCosto{
  font-size: 20px;
}
.spanDa{
  font-size: 16px;
}

.title-slider-home{
  margin-bottom: 24px;
}

.cardCosaOffriamo .card-title{
  margin-top: 16px;
}

.containerReset{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.containerReset .bloccoForm{
  width: 100%;
}
.subtitleResetPwd{
  font-feature-settings: "calt" off;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -.01em;
  line-height: 150%;
  text-align: center;
}

.help-block{
  color:red;
  margin-top: 5px;
  margin-bottom: 5px;
}


@media screen and (max-width: 991px) {
  .containerFooter,.percheHome,.componente-perch,.offertaHome,.destinationHome,.destinationDetail,.contenitoreCorpoTour,.destination,.containerVirtual{
    padding: 10px;
  }
  .footerMobile{
    display: none !important; 
  }
  .footerTablet{
    display: flex;
    flex-direction: column;
  }
  .flexRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .testo-coopiright{
    margin-top:43px;
    margin-bottom: 64px;
  }

  .container-Tours{
    padding: 24px;
  }
  .listDestination-home .card{
    height: 140px;
  }
  .listDestination-home .card-img-top{
    height: 100%;
  }

  .cardCosaOffriamo .card-img-top{
   height:230px;
  }
  .rowAccessibilit{
    padding: 10px;
  }

  .title-slider-home{
    font-size: 72px;
    letter-spacing:0em;
  }
  .testo-slider-home{
    font-size: 20px;
  }

  .title-card-home{
    font-size: 22px;
  }

  .titleDestination,.titleOfferta,.titolo-perche,.titleTours,.titleTours{
    font-size: 32px;
  }

  .cardCosaOffriamo .card-title{
    font-size: 22px;
    margin-top: 16px;
    margin-bottom: 0px;
  } 

  .cardCosaOffriamo .card-text{
    font-size: 16px;
    margin-top: 16px;
  } 

  .cardCosaOffriamo .card-body{
    padding-top: 0px;
  }

  .componente-perch{
    margin-top: 100px;
  }
  .contenitore-Perche{
    margin-top: 0px;
  }

  
.imgButton{
  margin:0px 0px;
  height: 15px;
}

.trip-button .imgButton{
  margin:0px 0px;
  height: 20px;
  object-fit: cover;
}

hr.solid{
  margin-top: 10px;
}
.buttonDisabilita {
  padding-left: 0px;
  width: auto !important;
  height: auto !important;
  font-size: 14px;
}
.selectCorpo .imgButton,.buttonPrezzo .imgButton{
  display: none;
}
.container-cardDestination .title-card-home{
  font-size: 28px;
}
.detailCard .card-img-top{
height: 215px !important;
}
.linkFooterWidth {
  width: 58%;
}

.imgPartner{
  width: auto;
  height: 90px;
}

.imgPartner_2{
  width: auto;
  height: 109px;
}
}


@media screen and (max-width: 479px) {
  .offertaHome{
    margin-left: auto; 
    margin-right: auto;
    max-width: 479px;

  } 
.inputWrapp{
  flex-direction: column;
}

  .footerMobile{
    display: flex !important; 
  }
  .footerTablet{
    display: none;
    flex-direction: column;
  }
  .inputModalDiv{
    width: auto;
  }
  .testo-coopiright,.linkFooterWidth{
    width: 100%;
    margin-bottom: 20px;
  }
  .testo-subfooter{
    margin-bottom: 20px;
  }

  .mobile{
    flex-direction: column;
  }

  .imgPartner,.imgPartner_2{
    width: 30%;
    height: auto;
    margin-bottom: 40px;
  }

  .cardCosaOffriamo{
    margin-left: 0px;
  }

  .blockPerche {
     width: 100%; 
     margin-right: 0px;
  }


  .title-slider-home{
   font-size:40px;
   margin-bottom: 16px;
  }
  .testo-slider-home{
   font-size:16px;
  }
 .carousel.carousel-slider
 .control-arrow {
   display: flex;
   width: 48px;
   height: 48px;
   top: auto;
   color: #fff;
   font-size: 26px;
   bottom: 70px !important;
   margin-top: 0;
   padding: 5px;
   position: absolute;
   border: 2px solid #fff;
   justify-content: center;
   align-items: center;
   border-radius: 16px;
 }
  .homeImg{
   padding: 25px;
  }
 
  .carousel-slider{
  display: flex;
  flex-direction: column;
  }
  .destinationHome{
   margin-top: 64px;
  }
  .offertaHome {
   margin-top: 80px;
  }
  .titleDestination,.titleTours {
   font-size: 24px;
  }
 
  .listDestination-home{
   display: flex;
   flex-direction: row;
   justify-content: start;
   overflow: scroll;
   margin-top: 24px;
   gap: 6px;
  
 }
 .flex-row-start{
   display: flex;
   flex-direction: row;
   justify-content: start;
 }
 
 .listDestination-home .card{
   margin-right: 10px;
   width: 220px;
   height: 275px;
 } 
 .card-img-top{
   height: 100%;
 }
 
 .cardCosaOffriamo, .blockPerche,.componente-perch{
   margin-bottom: 40px;
 }
 .container-Tours,.percheHome {
 margin-top: 0px;
 }
 .slick-slider{
   margin-bottom: 0px;
 }
 .container-Tours .card{
   width: 222px;
 }
 .card-text-tours {
 
  font-size: 18px;
 }
 .container-Tours .card-img-top{
   height: 275px;
 }
 
 .containerFooter{
   padding: 10px;
 }
 
 .containerFooter .footerMobile{
   flex-direction: column;
 }
 .footerMobileLink{
   flex-direction: row;
 }
 .subtitle-newsletter{
  margin: 16px 16px;
 }
 .btn-newsletter{
  width: 320px;
  margin: 16px 16px;

 }

 .dicono-title{
  align-items: flex-start;
 } 

 .footerMobile .btn-newsletter{
  margin-left: 0px;
  width: auto;
  padding: 0px 16px;
 }
 .card.dicono{
   width: 375px;
 }

 .container-home{
  margin-bottom: 60px;
 }

 .container-home .slick-list{
  height: 520px;
 
 }
 .carousel .control-dots {
  bottom: 55px;
}
.text-newsletter-footer{
  margin-top: 40px;
}
.linkFooterSocialWidth{
  margin-bottom: 32px;
  width: 57%;
}

.logoFooterIcon{
  margin-right: 24px;
}

.logoFooter{
  margin-bottom: 48px;
}

.text-newsletter{
  font-size: 24px !important;
  margin-bottom: 0px;
}
.subtitle-newsletter {
  margin: 0px 16px;
}

.btn-newsletter{
  margin-top:0px;
}


.slick-list{
  height: 456px;
}

.contenitore-Perche{
  margin-top: 0px;
}

.right-button{
  height: 40px;
  padding: 8px 0px 8px 0px;
}

.titleDestination,.titleOfferta,.titleTours{
  font-size: 24px !important;
}

 }

